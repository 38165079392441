@font-face {
  font-family: "Poppins Medium";
  src: url("./fonts/poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./fonts/poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Light";
  src: url("./fonts/poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins Regular";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
